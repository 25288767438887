<template>
  <div>
    <!-- ------------------------------------------------------------ -->
    <!-- Retailers Details Form Area -->
    <!-- ------------------------------------------------------------ -->
    <el-card shadow="always" :body-style="{ padding: '50px' }">
      <el-collapse value="details">
        <el-collapse-item name="details">
          <template slot="title">
            <div class="details_header">
              <h1>
                {{
                  $t("RetailersDEtailsPageTitleName", [retailersDetails.name])
                }}
              </h1>
              <h2>{{ $t("RetailersDEtailsPageTitleId", [id]) }}</h2>
            </div>
          </template>

          <el-form
            :model="retailersDetails"
            @submit.native.prevent="updateRetailersDetails"
            ref="form"
            :rules="editRetailerRules"
            label-position="left"
            label-width="200px"
            size="normal"
          >
            <div class="u-bottom-margin--2x">
              <el-button
                v-if="!retailerDetailsEditable"
                type="primary"
                size="mini"
                @click="retailerDetailsEditable = true"
              >
                {{ $t("RetailersDEtailsPageBtnEdit") }}
              </el-button>
              <template v-else>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-close"
                  @click="cancelEditMode"
                >
                  {{ $t("RetailersDEtailsPageBtnCancel") }}</el-button
                >
                <el-button type="primary" size="mini" native-type="submit">{{
                  $t("RetailersDEtailsPageBtnSaveData")
                }}</el-button>
              </template>
            </div>
            <el-row :gutter="200">
              <!-- --------------------- -->
              <!-- Editable column Area -->
              <!-- --------------------- -->
              <el-col :span="12" :offset="0">
                <el-form-item
                  prop="name"
                  :label="$t('RetailersDetailsPageInputName')"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.name"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="agentCode"
                  :label="$t('RetailersDetailsPageInputAgentCode')"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.agentCode"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="address"
                  :label="$t('RetailersDetailsPageInputAddress')"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.address"
                  >
                    <i
                      v-if="this.retailersDetails.location.lat"
                      class="el-icon-location el-input__icon"
                      slot="suffix"
                      @click="openMap"
                    >
                    </i
                  ></el-input>
                </el-form-item>
                <el-form-item prop="regionId" label="Region" size="normal">
                  <el-select
                    v-model="retailersDetails.regionId"
                    :disabled="!retailerDetailsEditable"
                    @change="clearLocations"
                    value-key=""
                    placeholder=""
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in allRegions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  prop="entityAreaId"
                  :label="$t('RetailersDetailsPageInputArea')"
                >
                  <el-select
                    v-model="retailersDetails.entityAreaId"
                    :disabled="!retailerDetailsEditable"
                    @change="clearAddress"
                    value-key=""
                    placeholder=""
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in lookups.areas"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="subArea" label="SubArea">
                  <el-select
                    v-model="retailersDetails.districtId"
                    :disabled="!retailerDetailsEditable"
                    value-key=""
                    placeholder=""
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in subAreas"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  prop="totalPoints"
                  :label="$t('RetailersDetailsPageInputTotalPoints')"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model.number="retailersDetails.totalPoints"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('RetailersDetailsPageInputIsActive')"
                  size="normal"
                >
                  <el-switch
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.active"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </el-form-item>

                <el-form-item
                  prop="notes"
                  :label="$t('RetailersDetailsPageInputNotes')"
                  size="normal"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.notes"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  prop="productOffersPercentage"
                  :label="$t('product Offers Percentage')"
                  size="normal"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model.number="retailersDetails.productOffersPercentage"
                  >
                    <span slot="prefix">%</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- --------------------- -->
              <!-- Display only column Area -->
              <!-- --------------------- -->
              <el-col :span="12" :offset="0">
                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputType')"
                >
                  <el-select
                    @change="getChannelTypes"
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.entityTypeId"
                    filterable
                  >
                    <el-option
                      v-for="item in lookups.entity_types"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <template v-if="channelTypes.length && channelTypes.length > 0">
                  <el-form-item label-width="200px" label="Channel Type">
                    <el-select
                      :disabled="!retailerDetailsEditable"
                      v-model="retailersDetails.businessTypeId"
                      filterable
                    >
                      <el-option
                        v-for="item in channelTypes"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputTxCount')"
                >
                  <el-input
                    disabled
                    v-model="retailersDetails.totalCountOfTransactions"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputCompletedTxCount')"
                >
                  <el-input
                    disabled
                    v-model="retailersDetails.countOfCompletedTransactions"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputSumOfTx')"
                >
                  <el-input
                    disabled
                    v-model="retailersDetails.sumOfCompletedTransactions"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputCancellationRate')"
                  size="normal"
                >
                  <el-input
                    disabled
                    v-model="retailersDetails.entityCancellationRate"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputCreatedAt')"
                >
                  <el-date-picker
                    disabled
                    v-model="retailersDetails.created_at"
                    size="normal"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label-width="200px"
                  :label="$t('RetailersDetailsPageInputLastTransactionDate')"
                >
                  <el-date-picker
                    disabled
                    v-model="retailersDetails.lastTransactionDate"
                    size="normal"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  prop="gpsGeneratedLocation"
                  label="GPS Location URL"
                  size="normal"
                >
                  <el-input
                    :disabled="!retailerDetailsEditable"
                    v-model="retailersDetails.location"
                  >
                    <i
                      @click="openGpsLocation(retailersDetails?.location)"
                      class="el-icon-location customLocationBtn"
                      slot="prefix"
                    ></i>
                  </el-input>
                </el-form-item>
                <el-form-item
                  prop="cashbackCappingPercentage"
                  label="Cashback Capping"
                  size="normal"
                >
                  <el-input
                    type="number"
                    :disabled="!retailerDetailsEditable"
                    v-model.number="retailersDetails.cashbackCappingPercentage"
                  >
                    <span slot="prefix">%</span>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <!-- ------------------------------------------------------------ -->
    <!-- Retailers Users Area -->
    <!-- ------------------------------------------------------------ -->
    <el-card :body-style="{ padding: '50px' }">
      <el-radio-group @change="getDisplayedData" v-model="displayType">
        <el-radio-button label="users">{{
          $t("RetailersDetailsPageTabUsers")
        }}</el-radio-button>
        <el-radio-button label="deactivatedUsers"
          >Deactivated Users</el-radio-button
        >
        <el-radio-button label="blacklist">{{
          $t("RetailersDetailsPageTabBlacklist")
        }}</el-radio-button>
      </el-radio-group>
      <template v-if="displayType">
        <div
          v-if="displayType === 'users'"
          class="u-top-margin--4x u-bottom-margin--4x actions-container"
        >
          <el-input
            v-model="secondPhone"
            placeholder="Filter by second phone number"
            size="normal"
            clearable
          ></el-input>
          <el-button
            type="primary"
            size="default"
            @click="showProfileDialog = true"
            >Add New User to this Entity</el-button
          >

          <user-profile-dialog
            :isDialogVisible="showProfileDialog"
            @user-saved="addNewProfile"
            @dialog-closed="showProfileDialog = false"
          ></user-profile-dialog>
        </div>
        <styled-table @rowClicked="loadUserProfile" :data="displayedData">
          <template v-for="col in displayedColumn">
            <el-table-column
              v-if="col.EditField"
              :prop="col.prop"
              :label="$t(col.label)"
              width="220"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <input-edit-field
                  v-if="col.prop == 'userName'"
                  :label="scope.row.userName"
                  @edit-field-saved="updateRetailerUserName($event, scope.row)"
                ></input-edit-field>
                <input-edit-field
                  v-else
                  :label="scope.row.secondPhone"
                  type="phone"
                  @edit-field-saved="
                    updateRetailerSecondPhone($event, scope.row)
                  "
                ></input-edit-field>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="!col.parseActiveToBool"
              :prop="col.prop"
              :label="$t(col.label)"
              :width="col.width ? col.width : ''"
              :key="col.prop"
            >
            </el-table-column>
            <el-table-column
              v-else-if="col.showOperations"
              :prop="col.prop"
              :label="$t(col.label)"
              width="220"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <div class="user-actions">
                  <el-button
                    @click="updateRetailerActivation(true, scope.row.id)"
                    >Reactivate</el-button
                  >
                  <el-button @click="reassignProfileDialog = true"
                    >Reassign</el-button
                  >
                </div>
                <el-dialog
                  title="Reassign User to new Entity"
                  :visible.sync="reassignProfileDialog"
                  width="30%"
                  @close="newEntitiyID = null"
                >
                  <el-select
                    v-model="newEntitiyID"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Select new entity"
                    :remote-method="remoteMethod"
                  >
                    <el-option
                      v-for="item in entitiesList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.storeName"
                    >
                      {{ `${item.storeName} - ${item.name} - ${item.address}` }}
                    </el-option>
                  </el-select>

                  <span slot="footer">
                    <el-button @click="reassignProfileDialog = false"
                      >Cancel</el-button
                    >
                    <el-button
                      type="primary"
                      :disabled="newEntitiyID === null"
                      @click="addNewProfile(scope.row, true)"
                      >OK</el-button
                    >
                  </span>
                </el-dialog>
              </template>
            </el-table-column>

            <el-table-column
              v-else
              :prop="col.prop"
              :label="$t(col.label)"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    type="danger"
                    size="small"
                    @click="updateRetailerActivation(false, scope.row.userId)"
                    >Deactivate</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </template>
        </styled-table>
      </template>
      <div v-else class="msg-page u-font--super">
        {{ $t("RetailersDetailsPageNoUserTab") }}
      </div>
    </el-card>
    <!-- ------------------------------------------------------------ -->
    <!-- Retailers Rewards Section -->
    <!-- ------------------------------------------------------------ -->
    <el-card>
      <div slot="header" class="clearfix">
        <span class="rewardsCardHeader"
          ><i class="el-icon-star-on"></i>Rewards</span
        >
      </div>
      <template>
        <!-- CashBack Info -->
        <el-descriptions title="Cashback Balance: " class="cashbackArea">
          <el-descriptions-item label="Current Month Cashback">
            <el-tag size="large" type="success"
              >{{ retailersDetails.cashbackBalance }} EGP</el-tag
            >
          </el-descriptions-item>
          <el-descriptions-item label="Last Month Cashback">
            <el-tag size="large" type="info"
              >{{ retailersDetails.lastRedeemedCashback }} EGP</el-tag
            >
          </el-descriptions-item>
        </el-descriptions>
        <!-- CashBack Info -->

        <!-- PromoCodes Area -->
        <div class="promoCodesArea">
          <span class="table-name">PromoCodes:</span>
          <styled-table
            :data="retailersDetails.promocodes"
            class="ratingsTable"
            style="width: 100%"
          >
            <el-table-column
              v-for="col in rewardsTableCols"
              :key="col.prop"
              :prop="col.prop"
              :label="$t(col.label)"
            >
            </el-table-column>
            <el-table-column prop="endDate" label="Expiry Date" width="140">
              <template slot-scope="prop">
                <div>{{ prop.row.endDate | moment("DD/MM/YYYY") }}</div>
                <div class="small-text">
                  {{ prop.row.endDate | moment("h:mm a") }}
                </div>
              </template>
            </el-table-column>
          </styled-table>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
import styledTable from "../../../components/dataContainers/styledTable.vue";
import InputEditField from "../../../components/InputEditField.vue";
import UserProfileDialog from "./UserProfileDialog.vue";
import { rewardsTableCols } from "./data";
import { retailersService } from "../../../services";
import { mapState } from "vuex";
import { entitiesServices } from "../../../services/entities.service";
import extendedFunctions from "../../../utils/extendedFunctions";
export default {
  components: { styledTable, InputEditField, UserProfileDialog },
  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      rewardsTableCols: rewardsTableCols,
      retailerDetailsEditable: false,
      retailersDetails: {
        id: 0,
        name: "",
        entityType: "",
        created_at: "",
        active: 0,
        area: "",
        region: "",
        address: "",
        notes: "",
        agentCode: "",
        totalPoints: 0,
        lastTransactionDate: "",
        totalCountOfTransactions: 1,
        countOfCompletedTransactions: 1,
        sumOfCompletedTransactions: 1,
        entityCancellationRate: 0,
      },
      editRetailerRules: {
        cashbackCappingPercentage: [
          {
            type: "number",
            min: 0,
            max: 100,
            message: "Value should be from 0 to 100",
            trigger: "change",
          },
          // { validator: this.percentageChecker, trigger: "change" },
        ],

        name: [
          {
            required: true,
            message: "Must Input data ",
            trigger: "blur",
          },
          {
            min: 5,
            max: 70,
            message: "Store Name length should be 5 to 70",
            trigger: "change",
          },
        ],
        agentCode: [
          {
            min: 0,
            max: 50,
            message: "Agent code length should be 0 to 50",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "Must Input data ",
            trigger: "blur",
          },
          {
            min: 10,
            max: 100,
            message: "Address length should be 10 to 100",
            trigger: "change",
          },
        ],
        regionId: [
          {
            required: true,
            message: "Must select an Region ",
            trigger: "change",
          },
        ],
        entityAreaId: [
          {
            required: true,
            message: "Must select an Area ",
            trigger: "change",
          },
        ],
        totalPoints: [
          {
            type: "number",
            min: 0,
            max: 5000,
            message: "Points length should be 0 to 5000",
            trigger: "change",
          },
        ],
        notes: [
          {
            min: 0,
            max: 500,
            message: "Notes length should be 0 to 500",
            trigger: "change",
          },
        ],
        productOffersPercentage: [
          {
            type: "number",
            min: 0,
            max: 100,
            message: "productOffersPercentage value should be from 0 to 100",
            trigger: "change",
          },
        ],
      },
      blackListUsers: [],
      blackListColumn: [
        { prop: "entityId", label: "RetailersDetailsPageTableColumnEntityId" },
        { prop: "name", label: "RetailersDetailsPageTableColumnName" },
        {
          prop: "created_at",
          label: "RetailersDetailsPageTableColumnCreatedAt",
        },
      ],
      retailerUsers: [],
      retailerUsersColumn: [
        { prop: "userId", label: "RetailersDetailsPageTableColumnId" },
        {
          prop: "userName",
          label: "RetailersDetailsPageTableColumnName",
          EditField: true,
        },
        { prop: "userPhone", label: "RetailersDetailsPageTableColumnPhone" },
        { prop: "secondPhone", label: "second phone", EditField: true },

        {
          prop: "userCreationDate",
          label: "RetailersDetailsPageTableColumnCreatedAt",
        },
        {
          prop: "userLastSeenDate",
          label: "RetailersDetailsPageTableColumnLastSeenAt",
        },
        {
          prop: "userLastTransactionDate",
          label: "RetailersDetailsPageTableColumnLastTransactionAt",
        },
        {
          prop: "userActiveFlag",
          label: "RetailersDetailsPageTableColumnActive",
          parseActiveToBool: true,
        },
      ],
      retailerDeactivatedUsersColumn: [
        { prop: "id", label: "RetailersDetailsPageTableColumnId" },
        {
          prop: "name",
          label: "RetailersDetailsPageTableColumnName",
        },
        { prop: "phone", label: "RetailersDetailsPageTableColumnPhone" },

        {
          prop: "created_at",
          label: "RetailersDetailsPageTableColumnCreatedAt",
        },
        {
          prop: "suspended_at",
          label: "Deactivated at",
        },

        {
          prop: "deactivationComment",
          label: "Deactivation comment",
        },
        {
          prop: "Operations",
          label: "Operations",
          parseActiveToBool: true,
          showOperations: true,
        },
      ],

      displayType: "users",
      showProfileDialog: false,
      reassignProfileDialog: false,
      newEntitiyID: null,
      secondPhone: null,

      entitiesList: [],
      channelTypes: [],
      subAreas: [],
    };
  },
  created() {
    this.getRetailer();
    this.getRetailerUsers();
  },
  computed: {
    allRegions() {
      if (this.lookups) {
        const mappedParentRegions = this.lookups?.regions.map((region) => {
          return { name: region.name, id: region.id };
        });
        const allRegions = [
          ...this.lookups.regionsChildren,
          ...mappedParentRegions,
        ];
        return allRegions;
      }
      return [];
    },
    displayedData() {
      return this.displayType == "blacklist"
        ? this.blackListUsers
        : this.retailerUsers.filter((user) =>
            this.secondPhone && this.displayType != "deactivatedUsers"
              ? user.secondPhone === this.secondPhone
              : true
          );
    },
    displayedColumn() {
      if (this.displayType == "blacklist") return this.blackListColumn;
      else if (this.displayType == "users") return this.retailerUsersColumn;
      return this.retailerDeactivatedUsersColumn;
    },

    ...mapState({
      lookups: (state) => state.Lookups.lookups,
    }),
  },
  methods: {
    percentageChecker(rule, value, callback) {
      if (value && (value < 1 || value > 100)) {
        callback(new Error("Please input percentage value from 1~100"));
      } else {
        callback();
      }
    },
    // ******************************************
    // Retailers Details Card
    // ******************************************
    cancelEditMode() {
      this.getRetailer().then(() => {
        this.retailerDetailsEditable = false;
      });
    },
    async getChannelTypes() {
      try {
        let channelTypesDetails = await retailersService.getChannelTypes(
          this.retailersDetails.entityTypeId
        );
        this.channelTypes = channelTypesDetails;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async getSubAreas() {
      try {
        let subAreasDetails = await retailersService.getSubAreas(
          this.retailersDetails.entityAreaId
        );
        this.subAreas = subAreasDetails;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    extractLatLongFromGoogleMapsURL(url) {
      const match = url.match(/q=(-?\d+\.\d+),(-?\d+\.\d+)/);
      if (match) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[2]);
        return { lat, lng };
      } else if (!match && url !== "No location Found!") {
        this.$globalFunctions.popupMessageWrapper(
          "The location that you are trying to enter is not correct. Please Try Again.",
          "error",
          2000
        );
        return undefined;
      }
    },
    generateGoogleMapsURL(latitude, longitude) {
      if (!latitude || !longitude) return "No location Found!";
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    },
    async getRetailer() {
      try {
        let retailersDetailsRes = await retailersService.getRetailerDetails(
          this.id
        );

        this.retailersDetails = {
          ...retailersDetailsRes,
          districtId: retailersDetailsRes?.entityDistrict?.id,
        };
        this.retailersDetails.location = this.generateGoogleMapsURL(
          this.retailersDetails.location.lat,
          this.retailersDetails.location.lng
        );
        this.getChannelTypes();
        this.getSubAreas();
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async updateRetailersDetails() {
      const valid = await this.$refs["form"].validate();
      if (!valid) return;
      try {
        let retailerUpdatedData = {
          storeName: this.retailersDetails.name,
          agentCode: this.retailersDetails.agentCode,
          address: this.retailersDetails.address,
          areaId: this.retailersDetails.entityAreaId,
          totalPoints: this.retailersDetails.totalPoints,
          active: this.retailersDetails.active,
          notes: this.retailersDetails.notes,
          typeId: this.retailersDetails.entityTypeId,
          districtId:
            this.retailersDetails.districtId === ""
              ? null
              : this.retailersDetails.districtId,
          location: this.extractLatLongFromGoogleMapsURL(
            this.retailersDetails.location
          ),
          businessTypeId:
            this.channelTypes.length && this.channelTypes.length > 0
              ? this.retailersDetails.businessTypeId
              : null,
          productOffersPercentage:
            this.retailersDetails.productOffersPercentage,
          cashbackCappingPercentage:
            this.retailersDetails.cashbackCappingPercentage,
        };
        await retailersService.updateRetailer(this.id, retailerUpdatedData);
        this.retailerDetailsEditable = false;
        this.$notify({
          title: "Success",
          message: "Retailer Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    openMap() {
      const urlSuffix = `${this.retailersDetails.location.lat},${this.retailersDetails.location.lng} `;
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    openGpsLocation(url) {
      window.open(url);
    },
    clearAddress(status) {
      this.retailersDetails.address = "";
      this.retailersDetails.districtId = "";
      if (this.retailersDetails.entityAreaId && status) {
        this.getSubAreas();
      }
    },
    clearLocations() {
      this.clearAddress(false);
      this.retailersDetails.entityAreaId = "";
    },
    // ******************************************
    // Retailers Users Card
    // ******************************************
    getDisplayedData() {
      if (this.displayType == "blacklist") return this.getBlacklist();
      else if (this.displayType == "deactivatedUsers")
        return this.getRetailerDeactivatedUsers();
      this.getRetailerUsers();
    },
    async getBlacklist() {
      try {
        let blacklistRes = await retailersService.getBlacklistUsers(this.id);
        this.blackListUsers = blacklistRes.blacklistUsers;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async getRetailerUsers() {
      try {
        let res = await retailersService.getRetailersUsers(this.id);
        this.retailerUsers = res.retailerUsers.filter(
          (user) => user.userActiveFlag
        );
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async getRetailerDeactivatedUsers() {
      try {
        let res = await retailersService.getRetailersDeactivatedUsers(this.id);
        this.retailerUsers = res.data.data.result;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async updateRetailerUserName(name, row) {
      try {
        await retailersService.updateRetailersUsers(this.id, row.userId, {
          userName: name,
        });
        let userIndex = this.retailerUsers.findIndex(
          (r) => r.userId == row.userId
        );
        this.$set(this.retailerUsers[userIndex], "userName", name);
        this.$notify({
          title: "Success",
          message: "User Name Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async updateRetailerSecondPhone(phone, row) {
      const shouldUpdate = await this.$confirm(
        "Are you sure you want to update the second phone.",
        {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }
      );
      if (!shouldUpdate) return;
      try {
        await retailersService.updateRetailersUsers(this.id, row.userId, {
          secondPhone: phone,
        });
        let userIndex = this.retailerUsers.findIndex(
          (r) => r.userId == row.userId
        );
        this.$set(this.retailerUsers[userIndex], "secondPhone", phone);
        this.$notify({
          title: "Success",
          message: "second phone Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },

    async updateRetailerActivation(activationValue, userId) {
      const activationObject = { suspended_at: null };
      if (activationValue == false) {
        if (this.retailerUsers.length === 1) {
          const shouldDeactivate = await this.$confirm(
            "Deactivating this user will deactivate the whole retailer entity.",
            {
              confirmButtonText: "confirm",
              cancelButtonText: "cancel",
              type: "warning",
            }
          );
          if (!shouldDeactivate) return;
        }
        const deactivationReason = await this.$prompt(
          "Please Enter Deactivation Reason",
          "Deactivate User",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            inputPattern: /[a-zA-Z0-9]/,
            inputErrorMessage: "Must enter a Reason",
          }
        );
        const deactivationDate = new Date().toString();
        activationObject.suspended_at = deactivationDate;
        activationObject.deactivationComment = deactivationReason.value;
      } else {
        const reactiveConfirmed = await this.$confirm(
          "Are you sure you want to reactive the User",
          {
            confirmButtonText: "confirm",
            cancelButtonText: "cancel",
            type: "warning",
          }
        );
        if (!reactiveConfirmed) return;
      }
      try {
        await retailersService.updateRetailersUsers(this.id, userId, {
          ...activationObject,
        });
        if (activationValue == false) this.getRetailerUsers();
        else this.getRetailerDeactivatedUsers();
        this.$notify({
          title: "Success",
          message: "User Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async addNewProfile(profileData, fromDeactivationList = false) {
      const isFromReassign = this.newEntitiyID ? true : false;
      const sendObj = {
        ...profileData,
      };
      const entityId = isFromReassign ? this.newEntitiyID : this.id;
      if (isFromReassign) {
        const isConfirmed = await this.$confirm(
          "Are you sure you want to reassign the User to then entity",
          {
            confirmButtonText: "confirm",
            cancelButtonText: "cancel",
            type: "warning",
          }
        );
        if (!isConfirmed) return;
      }
      try {
        await entitiesServices.addNewUserProfileToEntity(entityId, sendObj);
        this.showProfileDialog = false;

        if (!fromDeactivationList) {
          this.getRetailerUsers();
          this.$notify({
            title: "Success",
            message: "Retailer User Created successfully",
            type: "success",
          });
        } else {
          this.getRetailerDeactivatedUsers();
          this.reassignProfileDialog = false;
          this.$notify({
            title: "Success",
            message: "Retailer User Reassigned successfully",
            type: "success",
          });
        }
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },

    async remoteMethod(query) {
      if (query !== "") {
        const filtersObject = {
          storeName: query.trim(),
          areaIds: this.openedProviderAreas,
        };
        try {
          const res = await entitiesServices.fetchEntities(1, filtersObject);
          if (res) {
            this.entitiesList = res.data.data.entities;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 2rem;
}
.el-collapse {
  border: none;
}
::v-deep .el-collapse-item__header {
  height: auto;
  border: none;
}

h1 {
  font-size: 1.75rem;
}
h1,
h2 {
  margin: 0;
  padding: 0;
}
.msg-page {
  text-align: center;
  margin: 3rem;
}
.el-input__icon {
  font-size: 20px;
}
.el-input__icon {
  cursor: pointer !important;
  color: #fdd742;
  &:hover {
    color: #fdd842aa;
  }
}
.actions-container {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: auto;
  }
}
.user-actions {
  display: flex;
  justify-content: space-around;
}
</style>
<style>
.customLocationBtn {
  cursor: pointer;
  transition: 0.3s all;
}

.customLocationBtn:hover {
  color: #fccd13;
}
.cashbackArea .el-descriptions-item__container {
  align-items: center;
}
.ratingsTable.el-table .cell {
  direction: ltr;
}
.ratingsTable .table-header:nth-child(1) {
  border-top-left-radius: 8px;
}
.ratingsTable .table-header:last-child {
  border-top-right-radius: 8px;
}
.ratingsTable .cell {
  direction: rtl;
}
.ratingsTable .table-row {
  background: #f4f7fa;
}
.ratingsTable .table-header {
  background: #fccd13;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}
.rewardsCardHeader {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rewardsCardHeader i {
  color: #fccd13;
  font-size: 24px;
}
.promoCodesArea .table-name {
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  margin-bottom: 20px;
  display: block;
}
</style>
